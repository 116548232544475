.noty_theme__bootstrap-v4.noty_bar {
  margin: 4px 0;
  overflow: hidden;
  position: relative;
  border: 1px solid transparent;
  border-radius: .25rem; }
  .noty_theme__bootstrap-v4.noty_bar .noty_body {
    padding: .75rem 1.25rem; }
  .noty_theme__bootstrap-v4.noty_bar .noty_buttons {
    padding: 10px; }
  .noty_theme__bootstrap-v4.noty_bar .noty_close_button {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: .5;
    background: transparent; }
  .noty_theme__bootstrap-v4.noty_bar .noty_close_button:hover {
    background: transparent;
    text-decoration: none;
    cursor: pointer;
    filter: alpha(opacity=50);
    opacity: .75; }

.noty_theme__bootstrap-v4.noty_type__alert,
.noty_theme__bootstrap-v4.noty_type__notification {
  background-color: #fff;
  color: inherit; }

.noty_theme__bootstrap-v4.noty_type__warning {
  background-color: #fcf8e3;
  color: #8a6d3b;
  border-color: #faebcc; }

.noty_theme__bootstrap-v4.noty_type__error {
  background-color: #f2dede;
  color: #a94442;
  border-color: #ebccd1; }

.noty_theme__bootstrap-v4.noty_type__info,
.noty_theme__bootstrap-v4.noty_type__information {
  background-color: #d9edf7;
  color: #31708f;
  border-color: #bce8f1; }

.noty_theme__bootstrap-v4.noty_type__success {
  background-color: #dff0d8;
  color: #3c763d;
  border-color: #d6e9c6; }
